<template>
    <div>
        <a-spin v-show="!showModal" :spinning="loading">
            <a-form ref="form" name="form" :model="formState" @finish="onSearch">
                <a-row>
                    <a-form-item class="ui-form__item" name="organizationId" label="影院组织">
                        <a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;"
                                  @change="getAllCinemaList">
                            <!-- <a-select-option :value="0">全部</a-select-option> -->
                            <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
                                {{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<!-- @change="getCardPolicyList" -->
                        <a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">
                            <a-select-option :value="0">全部</a-select-option>
                            <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
                                {{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="活动名称" name="name" class="ui-form__item">
                        <a-input v-model:value="formState.name" placeholder="请输入活动名称"></a-input>
                    </a-form-item>
                </a-row>

                <a-row v-show="showAll">
                  <!--  <a-form-item label="线上会员卡等级" name="cardId" class="ui-form__item">
                        <a-select v-model:value="formState.cardId" style="width: 190px;" placeholder="请选择线上会员卡等级">
                            <a-select-option :value="0">全部</a-select-option>
                            <a-select-option v-for="item in cardAllList" :key="item.id" :value="item.id">
                                {{ item.title }}</a-select-option>
                        </a-select>
                    </a-form-item> -->

                    <a-form-item label="是否通用" name="isUniversal" class="ui-form__item">
                        <a-select v-model:value="formState.isUniversal" style="width: 190px;" placeholder="请选择是否通用">
                            <a-select-option :value="''">全部</a-select-option>
                            <a-select-option :value="1">是</a-select-option>
                            <a-select-option :value="0">否</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="活动状态" name="isDisabled" class="ui-form__item">
                        <a-select v-model:value="formState.isDisabled" style="width: 190px;" placeholder="请选择活动状态">
                            <a-select-option :value="''">全部</a-select-option>
                            <a-select-option :value="0">已启用</a-select-option>
                            <a-select-option :value="1">已禁用</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="有效期开始时间" class="ui-form__item">
                        <a-range-picker v-model:value="startTime"></a-range-picker>
                    </a-form-item>

                    <a-form-item label="有效期结束时间" class="ui-form__item">
                        <a-range-picker v-model:value="endTime"></a-range-picker>
                    </a-form-item>
                </a-row>

                <a-row>
                    <a-col :span="18">
                        <a-button type="primary" v-permission="['coupon_stored_activity_add']" @click="onAdd">新增活动</a-button>
                        <a style="margin-left: 20px;" @click="showAll = !showAll">
                            {{ showAll ? '收起' :'展开' }}
                            <Icon v-show="!showAll" icon="DownOutlined"></Icon>
                            <Icon v-show="showAll" icon="UpOutlined"></Icon>
                        </a>
                    </a-col>
                    <a-col :span="6" style="text-align: right;">
                        <a-button type="primary" html-type="submit">搜索</a-button>
                        <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
                    </a-col>
                </a-row>
            </a-form>
            <a-table style="margin-top: 10px;" :dataSource="list" :columns="columns" :pagination="pagination" :scroll="{ x: 1600 }">
                <template #bodyCell="{ column, record, index }">

					<template v-if="column.key === 'time'">
						<div v-if="record.isLong">
							<a-tag color="blue">长期</a-tag>
						</div>
						<div v-else>
							{{ transDateTime(record.startTime, 1) }} - {{ transDateTime(record.endTime, 1) }}
						</div>
					</template>

					<template v-if="column.key === 'policy'">
						<a-tag :color="record.isUniversal === 1 ? 'green' : 'cyan'">{{ record.isUniversal === 1 ? '通用' : '非通用' }}</a-tag>
						<div v-if="!record.isUniversal" style="margin-top: 8px;">
							<a-button type="link" size="small" @click="onModalLevel(record)">查看适用卡等级</a-button>
						</div>
					</template>

                    <template v-if="column.key === 'isDisabled'">
                        <span v-if="record.isDisabled">已禁用</span>
                        <span v-else>已启用</span>
                    </template>

                    <template v-if="column.key === 'action'">
                        <a-dropdown :trigger="['click', 'hover']">
                            <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                            </a-button>
                            <template #overlay>
                                <a-menu>
                                    <div @click="onSee(record)">
                                        <a-menu-item>
                                            查看
                                        </a-menu-item>
                                    </div>
                                    <div v-permission="['coupon_stored_activity_edit']" @click="onEdit(record)">
                                        <a-menu-item>编辑</a-menu-item>
                                    </div>
                                    <div v-permission="['coupon_stored_activity_disabled']"
                                         v-if="record.isDisabled === 0" @click="onDisabled(record)">
                                        <a-menu-item>
                                            禁用
                                        </a-menu-item>
                                    </div>
                                    <div v-else
                                         v-permission="['coupon_stored_activity_open']"
                                         @click="onDisabled(record)">
                                        <a-menu-item>
                                            启用
                                        </a-menu-item>
                                    </div>
                                    <div v-permission="['coupon_stored_activity_delete']" @click="onDelete(record)">
                                        <a-menu-item>删除</a-menu-item>
                                    </div>
                                </a-menu>
                            </template>
                        </a-dropdown>
                    </template>
                </template>
            </a-table>

			<a-modal v-model:visible="levelModalVisible" width="600px" title="适用卡等级" :footer="false">
				<a-tag style="margin-bottom: 10px;" v-for="(item,index) in levelList" :key="index">{{ item.title }}</a-tag>
			</a-modal>
        </a-spin>
        <temp v-if="showModal" :id="id" :isEdit="isEdit" :isSee="isSee" @back="onBack"></temp>
    </div>
</template>

<script>
    import { Icon } from '@/components/icon/icon.js';
    import temp from './temp.vue';
    import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import { getCardPolicyList, getRechargeActivity, pullOnShelvesRechargeActivity, pullOffShelvesRechargeActivity, delRechargeActivity } from '@/service/modules/coupon.js';
    export default {
        components: { Icon, temp },
        data() {
            return {
                loading: false,
                showAll: false,
                organizationList: [],
                cinemaAllList: [],
                cardAllList: [],
                formState: {
					isUniversal: '',
					isDisabled: '',
					type:1
				},
				startTime: [],
				endTime: [],
                searchData: {},
                list: [],
                columns: [{
                    title: '所属影院',
					dataIndex: 'cinemaName',
                }, {
                    title: '活动名称',
					dataIndex: 'name',
					width: 250
                }, {
                    title: '活动有效期',
					key: 'time',
					width: 220
                }, {
                    title: '适用卡等级',
					key: 'policy'
                }, {
                    title: '充值金额',
					dataIndex: 'rechargeAmount',
					width: 120
                }, {
                    title: '实际到账金额',
					dataIndex: 'arrivalAmount',
					width: 150
                }, {
                    title: '状态',
                    key: 'isDisabled',
					width: 120
                }, {
                    title: '操作',
                    key: 'action',
                    width: 130,
                    fixed: 'right'
                }],
                pagination: {
                    showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
                },
                showModal: false,
                isEdit: false,
                isSee: false,
                id: 0,

				levelModalVisible: false,
				levelList: [],
            }
        },
        created() {
            this.getOrganizationList();
        },
        methods: {
            onBack(isRef) {
                this.showModal = false;
				if (isRef) {
					this.getData();
				}
            },
            onSearch() {
                this.pagination.current = 1;
                this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.cinemaId = this.searchData.cinemaId !== 0 ? this.searchData.cinemaId : undefined;
				this.searchData.isUniversal = this.searchData.isUniversal !== '' ? this.searchData.isUniversal : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
				if (this.startTime.length) {
					this.searchData.startTimeBegin = this.startTime[0].startOf('days').unix();
					this.searchData.startTimeEnd = this.startTime[1].endOf('days').unix();
				}
				if (this.endTime.length) {
					this.searchData.endTimeBegin = this.endTime[0].startOf('days').unix();
					this.searchData.endTimeEnd = this.endTime[1].endOf('days').unix();
				}
                this.getData();
            },
            reset() {
                this.$refs.form.resetFields();
				this.startTime = [];
				this.endTime = [];
                if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
            },
            async getData() {
                this.loading = true;
				try {
					let ret = await getRechargeActivity({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
            },
            async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				this.cardAllList = [];
				// this.formState.cardId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getCardPolicyList(cinemaId) {
				this.cardAllList = [];
				// this.formState.cardId = 0;
				let ret = await getCardPolicyList({
					page: 1,
					pageSize: 999999,
					type: 3,
					organizationId: this.formState.organizationId ? this.formState.organizationId : undefined,
					cinemaId: cinemaId ? cinemaId : undefined
				})
				if (ret.code === 200) {
					this.cardAllList = ret.data.list;
				}
			},
			onAdd() {
			    this.isEdit = false;
			    this.isSee = false;
			    this.id = 0;
			    this.showModal = true;
			},
			onEdit(record) {
			    this.isEdit = true;
			    this.isSee = false;
			    this.id = record.id;
			    this.showModal = true;
			},
			onSee(record) {
			    this.isEdit = false;
			    this.isSee = true;
			    this.id = record.id;
			    this.showModal = true;
			},
			onDelete(item) {
		        this.$confirm({
		            title: '提示',
		            content: '确定删除该充值活动吗？',
		            onOk: async () => {
		                this.loading = true;
		                try {
		                    let ret = await delRechargeActivity({
		                        id: item.id
		                    });
		                    this.loading = false;
		                    if (ret.code === 200) {
		                        this.$message.success('删除成功');
		                        this.getData();
		                    }
		                } catch(e){
		                    this.loading = false;
                        console.log(e)
		                }
		            }
		        })
			},
			onDisabled(record) {
			    this.$confirm({
			        title: '提示',
			        content: '确定' + (record.isDisabled ? '启用' : '禁用') + '该充值活动吗？',
			        onOk: async () => {
						this.loading = true;
						let ret;
						try {
							if (record.isDisabled) {
								ret = await pullOnShelvesRechargeActivity({
									id: record.id
								})
							} else {
								ret = await pullOffShelvesRechargeActivity({
									id: record.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((record.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
			        }
			    })
			},
			onModalLevel(record) {
				this.levelList = record.cardRechargeActivityList;
				this.levelModalVisible = true;
			}
        }
    }
</script>

<style scoped>
    .ui-form__item {
        margin-right: 20px;
    }
</style>
